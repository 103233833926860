import React from 'react';
import styled from 'styled-components/macro';
import colors from '../../colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { Dropdown } from 'react-bootstrap';
import IconDropdown from '../IconDropdown/IconDropdown';


export const StyledHeader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 15px;
`;

const VerticalCenteringDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${colors.WHITE};
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const StyledLinkContainerRight = styled.div`
  height: 100%;
  justify-content: space-around;
  display: flex;
`;

export const StyledLogo = styled.img`
  width: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
  image-rendering: -webkit-optimize-contrast;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = (props) => {
  const { signOut, homeRedirect, profile, admin, isAdmin, showProfile } = props;

  return (
    <StyledHeader id="header">
      <StyledLogoContainer id="button-and-logo-box">
        <StyledLogo
          id="logo"
          src="https://catalyte-resource-hosting.s3.amazonaws.com/images/logo_white.png"
          onClick={homeRedirect}
          alt="Catalyte logo"
          aria-label="Catalyte logo image"
        />
      </StyledLogoContainer>
      <StyledLinkContainerRight>
        {isAdmin && (
          <VerticalCenteringDiv>
            <IconDropdown id="support-dropdown" icon="cog">
              <Dropdown.Item onClick={admin}>Users</Dropdown.Item>
            </IconDropdown>
          </VerticalCenteringDiv>
        )}
        <VerticalCenteringDiv>
          <IconDropdown id="support-dropdown" icon="question-circle">
            <Dropdown.Item href="mailto:support@catalyte.io">Contact Support</Dropdown.Item>
          </IconDropdown>
        </VerticalCenteringDiv>
        <VerticalCenteringDiv>
          <IconDropdown id="profile-dropdown" icon="user-circle">
            {signOut && (
              <>
                {showProfile && 
                  <>
                    <Dropdown.Item onClick={profile} aria-label="Button for profile">Profile</Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                }
                <Dropdown.Item onClick={signOut} aria-label="Button for signing out">
                  Sign Out
                </Dropdown.Item>
              </>
            )}
          </IconDropdown>
        </VerticalCenteringDiv>
      </StyledLinkContainerRight>
    </StyledHeader>
  );
};

export default Header;
