import React from 'react';
import { SignUp } from 'aws-amplify-react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components/macro';
import { up } from 'styled-breakpoints';
import { withStyles } from '@material-ui/core/styles';
import AuthLayout from '../../containers/Auth/AuthLayout';
import './AuthComponents.scss';
import {Auth} from "aws-amplify";
import Loader from "../Loader/Loader";

const StyledBackdrop = withStyles({
  root: {
    zIndex: 10,
    color: '#fff'
  }
})(Backdrop);


const FedBtn = styled.div`
  border-color: black;
  border-width: 1px;
  border: 1px solid lightgrey;

  width: 99%;
  display: flex;
  cursor: pointer;
  margin-top: .5em;
  margin-bottom: .5em;
  height: 50px;

  border-radius: 6px;
  &:hover{
    background-color: lightgrey;
  }
  `
  const SignInText = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: .9em;
  }    
`

const TextInput = styled.input`
  width: 94.5%;
  height: 50px;
  display: flex;
  padding-left: 1em;
  border: 1px solid lightgrey;
  justify-content: center;
  border-radius: 6px;
  &:focus{
    border: 1px solid #6d9eeb;
    outline: none;
  }

  `


const LoginButton = styled.div`
  background-color: #6d9eeb;
  width: 100%;
  height: 50px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover{
    background-color: #4D85E7;
  }

`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Link = styled.a`
  color: #6d9eeb;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
`


const FormColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`
const ValidationMessage = styled.div`
  position: absolute;
  max-width: 300px;
  background-color: white;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 1em;
  line-height: 1.5;
`

class SignUpComponent extends SignUp {
    constructor(props) {
        super(props);
        this.signUp = this.signUp.bind(this);
        this.inputs = {
            username: '',
            password: '',
            confirmation: ''
        }

        this.state.isFirstPasswordAttempt = true;
        this.state.isFirstConfirmationAttempt = true;
        this.state.isValidConfirmedPassword = null;
        this.state.isValidPassword = null;
        this.state.isValidEmail = null;
        this.state.pwdToastMsg = '';
        this.state.showPwdToast = false;
        this.state.cnfrmToastMsg = '';
        this.state.showCnfrmToast = false;
        this.state.showEmailToast = false;
        this.state.isFirstEmailAttempt = true;
        this.state.emailToastMsg = 'Invalid email';
        this.state.loading = false;
    }

    validateEmail(email) {
      let validEmail = !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      return validEmail;
    }

    onEmailChange(e) {
      this.inputs.username = e.target.value;
      if(!this.state.isFirstEmailAttempt){
        this.setState({
          isValidEmail: this.validateEmail(e.target.value)
        })
      }
    }

    onEmailBlur(e) {
      this.setState({
        isFirstEmailAttempt: false,
        isValidEmail: this.validateEmail(e.target.value)
      })
    }

    onPasswordChange(e) {
      this.inputs['password'] = e.target.value;
      if(!this.state.isFirstPasswordAttempt){
          this.setState({isValidPassword: this.validatePassword(e.target.value)});
          if(!this.validatePassword(e.target.value)){
            this.setState({
              pwdToastMsg: this.getPasswordHelperText(e.target.value),
              showPwdToast: true
            })
          }else{
            this.setState({
              pwdToastMsg: '',
              showPwdToast: false
            })
          }
          if(this.state.isValidConfirmedPassword !== null){
              this.setState(
                {
                  isValidConfirmedPassword: e.target.value === this.inputs.confirmation,
                  showCnfrmToast: e.target.value === this.inputs.confirmation
                });
              
          }
      }
    }

    onPasswordBlur(e) {
      if(!this.validatePassword(e.target.value) && this.state.isFirstPasswordAttempt){
        this.setState({
          pwdToastMsg: this.getPasswordHelperText(e.target.value),
          showPwdToast: true,
          isFirstPasswordAttempt: false,
          isValidPassword: this.validatePassword(e.target.value)
        })
      }else {
        this.setState({
          isFirstPasswordAttempt: false,
          isValidPassword: this.validatePassword(e.target.value)
        });
      }
    }

    onConfirmedBlur(e) {
      this.setState({
        isFirstConfirmationAttempt: false
      })
    }

    onConfirmedPasswordChange(e){
      this.setState({confirmation: e.target.value});
      this.setState({isValidConfirmedPassword: e.target.value === this.inputs.password});

      if(e.target.value !== this.inputs.password && !this.state.isFirstConfirmationAttempt){
        this.setState({
          showCnfrmToast: true,
          cnfrmToastMsg: 'Passwords must match'
        })
      }else{
        this.setState({
          showCnfrmToast: false
        })
      }
    }

    validatePassword(v){
      if(!v){
          return false;
      }

      const validLength = v.length > 7;
      const hasNums = /\d/.test(v);
      const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
      const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

      let validPassword = validLength && hasNums && hasSpecial && hasMixedCase;
      return validPassword;
  }

    checkFieldsFilled() {
        const filled = this.inputs.username && this.inputs.password && this.inputs.confirmation;
            this.setState({
                fieldsFilled: filled
        });
    }

    passwordsMatch() {
      return this.inputs.password === this.inputs.confirmation;
    }

    getPasswordHelperText(v) {
      if(!v){
          return 'Password is required.'
      }

      const invalidLengthMsg = 'Password must be at least 8 characters.\n';
      const invalidNums = 'Password must contain at least one number.\n';
      const invalidSpecial = 'Password must contain at least one special character.\n';
      const invalidCase = 'Password must contain upper and lower case characters.';

      const validLength = v.length > 7;
      const hasNums = /\d/.test(v);
      const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
      const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

      let helperText = '';

      helperText = validLength ? helperText : helperText + invalidLengthMsg;
      helperText = hasNums ? helperText : helperText + invalidNums;
      helperText = hasSpecial ? helperText : helperText + invalidSpecial;
      helperText = hasMixedCase ? helperText : helperText + invalidCase;

      return helperText;
    }

    signUp() {
      this.setState({ loading: true });
        const authData =
        {
            username: this.inputs.username && this.inputs.username.substring(0, this.inputs.username.indexOf('@')),
            password: this.inputs.password,
            attributes: {
                email: this.inputs.username,
                locale: 'en',
          },
        }

        Auth.signUp(authData)
        .then((res) => {
            this.changeState('confirmSignUp');
            this.setState({ loading: false });
        })
        .catch((err) => {
            this.setState({ loading: false });
            if(err.code === 'UsernameExistsException') {
                alert('Email already registered')
            }
        });
    }

	handleInputChange(e) {
		this.inputs[e.target.id] = e.target.value;
	}

  federatedSignIn = (provider) => {
    this.setState({ loading: true });
    Auth.federatedSignIn({provider: provider})
    .then((cred) => {
      this.setState({ loading: false });
    })
    .then((user) => {
      this.setState({ loading: false });
    })
    .catch((err) => {
      this.setState({ loading: false });
      console.log(err)
    })
  }

  showComponent() {
    return (
      <AuthLayout>
        <Loader open={this.state.loading}/>
        <FormColumn onKeyUp={(e) => { console.log(e.keyCode); if(e.keyCode === 13) { this.signUp() }}}>
        <div style={{display: 'flex', flexDirection: 'column', maxWidth: '336px', width: '100%', justifyContent: 'center'}}>
            <Row>
              <div style={{marginLeft: '1em'}} id="odysseyLogo" />  
            </Row>
            <FedBtn
                    onClick={() => this.federatedSignIn("Google")}>
                <div style={{margin: '10px'}} id="googLogo"/>
                <SignInText>Continue with Google</SignInText>
              </FedBtn>
              <div>
                <TextInput
                  type="string"
                  id="username"
                  key="username"
                  label="email"
                  style={{ border: (this.state.isValidEmail || this.state.isValidEmail === null || this.state.isFirstEmailAttempt) ? '1px solid lightgrey' : '1px solid red' }}
                  placeholder="email"
                  onBlur={(e) => {
                    this.onEmailBlur(e);
                  }}
                  onChange={(e) => {
                    this.onEmailChange(e);
                    this.checkFieldsFilled();
                  }}
                />
                {
                  this.state.isValidEmail === false && 
                  <ValidationMessage style={{zIndex: 2}}>
                    { this.state.emailToastMsg }
                  </ValidationMessage>
                }
              </div>
              <div style={{marginTop: '.5em'}}>
                <TextInput
                  type="password"
                  id="password"
                  placeholder="password"
                  style={{ border: (this.state.isValidPassword || this.state.isValidPassword === null || this.state.isFirstPasswordAttempt) ? '1px solid lightgrey' : '1px solid red' }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && this.state.fieldsFilled) {
                      this.signUp();
                    }
                  }}
                  onBlur={(e) => {
                    this.onPasswordBlur(e);
                  }}
                  onChange={(e) => {
                    this.onPasswordChange(e);
                    this.checkFieldsFilled();
                  }}
                />
                {
                  this.state.showPwdToast && 
                  <ValidationMessage style={{zIndex: 2}}>
                    { this.state.pwdToastMsg }
                  </ValidationMessage>
                }
              </div>
              <div style={{marginTop: '.5em'}}>
                <TextInput
                  type="password"
                  id="confirmation"
                  placeholder="confirm password"
                  style={{ border: (this.state.isValidConfirmedPassword || this.state.isValidConfirmedPassword === null || this.state.isFirstConfirmationAttempt) ? '1px solid lightgrey' : '1px solid red' }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && this.state.fieldsFilled) {
                      this.signUp();
                    }
                  }}
                  onBlur={(e) => {
                    this.onConfirmedBlur(e);
                  }}
                  onChange={(e) => {
                    this.onConfirmedPasswordChange(e);
                    this.checkFieldsFilled();
                  }}
                />
                {
                  this.state.showCnfrmToast && 
                  <ValidationMessage style={{zIndex: 1}}>
                    { this.state.cnfrmToastMsg }
                  </ValidationMessage>
                }
              </div>
              <div>
                <LoginButton
                  disabled={!this.state.fieldsFilled}
                  text="Sign In"
                  onClick={() => this.signUp()}
                >Sign Up</LoginButton>
              </div>
              <Row style={{marginTop: '1em'}}>
                <Link style={{marginRight: '.5em'}}
                    className="op-logincomponent-backlink"
                    onClick={() => {
                      this.changeState('signIn');
                    }}>
                    Already registered? 
                  </Link>
                  <Link
                    className="op-logincomponent-backlink"
                    onClick={() => {
                      this.changeState('signIn');
                    }}>
                    Sign in
                  </Link>
                </Row>
            <StyledBackdrop open={this.state.loading || false}>
              <CircularProgress color="inherit" />
            </StyledBackdrop>
          </div>
        </FormColumn>
      </AuthLayout>
    );
  }
}

export default SignUpComponent;
