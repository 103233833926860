import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { validateEmail } from '../../utils/validation';
import AuthLayout from '../../containers/Auth/AuthLayout';
import NewPasswordInputComponent from './NewPasswordInputComponent';
import styled from 'styled-components/macro';
import PasswordRules from './PasswordRules';
import { up } from 'styled-breakpoints';
import './AuthComponents.scss';

const FormColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`
const TextInput = styled.input`
  width: 96%;
  height: 58px;
  border: 1px solid lightgrey;
  padding-left: 10px;
  border-radius: 6px;
  &:focus{
    border: 1px solid #6d9eeb;
    outline: none;
  }
  `

const Link = styled.div`
cursor: pointer;
&:hover{
text-decoration: underline;
}
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 336px;
  width: 100%;
  color: #6d9eeb;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledButton = styled.div`
  background-color: #6d9eeb;
  height: 50px;
  width: 100%;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover{
    background-color: #4D85E7;
  }`
class ForgotPasswordComponent extends ForgotPassword {
  constructor() {
    super();

    // this.state already initialized by parent class
    this.state.buttonEnabled = false;
    this.state.view = this.state.delivery ? 'submit' : 'send';
    this.state.newPwMatch = false;
    this.codeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.delivery !== prevState.delivery) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        view: this.state.delivery ? 'submit' : 'send'
      });
    }

    if (this.state.view === 'submit' && this.state.view !== prevState.view) {
      this.codeRef.current.value = '';
    }
  }

  checkButtonEnable = () => {
    let shouldBeEnabled;
    if (this.state.view === 'send') {
      shouldBeEnabled = this.inputs.username && validateEmail(this.inputs.username);
    } else {
      shouldBeEnabled = this.inputs.password && this.inputs.code && this.state.newPwMatch;
    }
    this.setState({
      buttonEnabled: shouldBeEnabled
    });
  };

  handleChangeView = (view) => {
    this.setState(
      {
        view
      },
      () => {
        this.checkButtonEnable();
      }
    );
  };

  handlePwCheck = (check) => {
    this.setState(
      {
        newPwMatch: check
      },
      () => {
        this.checkButtonEnable();
      }
    );
  };

  sendView = () => {
    return (
      <>
          <TextInput
            type="email"
            id="username"
            name="username"
            onChange={(e) => {
              this.handleInputChange(e);
              this.checkButtonEnable();
            }}
            placeholder="email address"
          />
      </>
    );
  };

  submitView = () => {
    return (
      <>
        <div className="op-logincomponent-label">Code*</div>
        <div className="op-logincomponent-input">
          <TextInput
            style={{marginBottom: '.5em'}}
            autoComplete="off"
            type="text"
            id="pwResetCode"
            name="code"
            ref={this.codeRef}
            onChange={(e) => {
              this.handleInputChange(e);
              this.checkButtonEnable();
            }}
          />
        </div>
        <NewPasswordInputComponent
          handleChange={(e) => {
            this.handleInputChange(e);
          }}
          handlePwCheck={(check) => this.handlePwCheck(check)}
        />
      </>
    );
  };

  showComponent() {
    return (
      <AuthLayout>
        <FormColumn                
              onKeyUp={(e) => {
                if(e.keyCode === 13){
                  return this.state.view === 'send' ? this.send() : this.submit();
                }
              }}>
          <Form>
            <Row>
              <div id="odysseyLogo" />  
            </Row>
            <h3>Reset your password</h3>
            {this.state.view === 'send' ? this.sendView() : this.submitView()}
              <StyledButton
                id="resetPwButton"
                disabled={!this.state.buttonEnabled}
                onClick={() => {
                  if(this.state.view ==='send'){
                    this.send();
                  }else{
                    this.submit();
                  }
                  this.handleChangeView('submit');
                }}
              >{this.state.view === 'send' ? 'Send Code' : 'Submit'}</StyledButton>
              <Link
                style={{marginTop: '1em'}}
                onClick={() => {
                  this.changeState('signIn');
                  this.handleChangeView('send');
                }}>
                Back to Login
              </Link>
            {this.state.view === 'submit' && <PasswordRules />}
          </Form>
        </FormColumn>
      </AuthLayout>
    );
  }
}

export default ForgotPasswordComponent;
