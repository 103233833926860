/* istanbul ignore file */
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { getParameterByName } from './utils/helpers';
import * as serviceWorker from './serviceWorker';
import initAmpify from './amplifyConfigure';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faUserCircle,
  faAngleDown,
  faQuestionCircle,
  faCloudUploadAlt,
  faCloudDownloadAlt
} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

// Make icons available globally - https://tinyurl.com/9ndwkhd9
library.add(
  faCog,
  faUserCircle,
  faAngleDown,
  faQuestionCircle,
  faCloudUploadAlt,
  faCloudDownloadAlt
);

const error_description = getParameterByName('error_description');

if (
  error_description &&
  error_description.indexOf('PreSignUp failed with error ExternalProviderLinked') > -1
) {
  let split = error_description.split('_');
  let provider = split.length > 1 ? split[1].replace('.', '') : null;

  if (provider) {
    window.location.replace(`${process.env.REACT_APP_env_url}?federatedSignIn=${provider}`);
  }
} else {
  const amp = initAmpify();

  if (process.env.REACT_APP_env_name !== 'prd') {
    window.Amp = amp;
  }

  (async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID
    });

    render(
      <LDProvider>
        <App />
      </LDProvider>,
      document.getElementById('root')
    );
  })();


  serviceWorker.unregister();
}
