export const constants = {
  IDPOOL_ID_ATTR: 'custom:identity_pool_id',
  PORTAL_APP_NAME: '🚀 Portal',

  ODYSSEY_APP_NAME: '⚙️ Admin Portal',

  PROFILE_APP_NAME: '👋 Profile',

  SCREEN_APP_NAME: '📺 Screen',
  SCREEN_APP_URL:
    process.env.REACT_APP_env_name === 'local'
      ? 'http://localhost:3001'
      : `https://screen.${process.env.REACT_APP_env_app_domain_base}/welcome`,

  SCREEN_APP_DESCRIPTION: 'Assessment for the Apprentice Program.',

  JOURNEY_APP_NAME: '🚌 Journey',
  JOURNEY_APP_URL:
    process.env.REACT_APP_env_name === 'local'
      ? 'http://localhost:3002'
      : `https://journey.${process.env.REACT_APP_env_app_domain_base}`,
  JOURNEY_APP_DESCRIPTION: 'Coach Associates on their Odyssey journey.',

  INVOICE_APP_NAME: '🧾 Invoice',
  INVOICE_APP_URL:
    process.env.REACT_APP_env_name === 'local'
      ? 'http://localhost:3003'
      : `https://invoice.${process.env.REACT_APP_env_app_domain_base}`,
  INVOICE_APP_DESCRIPTION: 'Manage 1099 invoices.',


  TABLEAU_APP_NAME: '📈 Tableau',
  TABLEAU_APP_URL: `https://tableau.catalyte.io/`,
  TABLEAU_APP_DESCRIPTION: 'Tableau data analytics',

  STAFF_MATCH_APP_NAME: '🧐 Staff Match',
  STAFF_MATCH_APP_URL:
    process.env.REACT_APP_env_name === 'local'
      ? 'http://localhost:3006'
      : `https://staffmatch.${process.env.REACT_APP_env_app_domain_base}`,
  STAFF_MATCH_APP_DESCRIPTION: 'Match workers to opportunities!',

  FEEDBACK_APP_NAME: '🔊 Feedback',
  FEEDBACK_APP_URL:
    process.env.REACT_APP_env_name === 'local'
      ? 'http://localhost:3004'
      : `https://feedback.${process.env.REACT_APP_env_app_domain_base}`,
  FEEDBACK_APP_DESCRIPTION: 'Provide feedback on all things feedbackable!',
};

export const eulaConstants = {
  NO_EULA_KEY: 'noEula_upgroup',
  NO_EULA_NAME: 'User has not accepted the EULA.'
};

export const storageConstants = {
  USERS: 'userList'
};
