import React from 'react';
import HeaderComponent from '../../components/Header/HeaderComponent';

export const exportFunctions = {};
// Fetching mock data in function, so it can be stubbed in testing - this will be replaced in useSelector


function HeaderContainer(props) {
  const { signOut, history, isAdmin, showProfile } = props;

  const homeRedirect = () => {
    history.push('/');
  };
  const profile = () => {
    history.push('/profile');
  };
  const admin = () => {
    console.log(props)
    history.push('/admin/users');
  };
  return (
    <>
      <HeaderComponent
        signOut={signOut}
        isAdmin={isAdmin}
        homeRedirect={homeRedirect}
        profile={profile}
        admin={admin}
        showProfile={showProfile}
      />
    </>
  );
}

export default HeaderContainer;
