import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import fonts from '../../fonts';

const StyledText = styled.p`
  font: 18px ${fonts.family.ROBOTO};
  color: #1b3e77;
`;

function LogoutNotification(props) {
  const { open, handleCancel, handleLogout, inactivityTimeout } = props;
  const inactivityMinutes = Math.round(inactivityTimeout / 1000 / 60);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}>
      <DialogTitle
        disableTypography
        style={{ font: '22px Montserrat', color: '#1b3e77' }}
        id="confirmation-dialog-title">
        Session Expired
      </DialogTitle>
      <DialogContent>
        <StyledText>
          Your session is innactive. Click continue to keep working or sign out to sign out.
        </StyledText>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel-logout"
          variant="contained"
          onClick={handleCancel}
        >Cancel</Button>
        <Button
          id="signout"
          color="primary"
          variant="contained"
          onClick={handleLogout}
          text="Sign Out"
        >Sign Out</Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutNotification;
