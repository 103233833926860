/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { constants as c, storageConstants as storage } from './utils/constants';
import Dashboard from './containers/Dashboard/Dashboard';
import Eula from './containers/Eula/Eula';
import ToastComponent from './components/Toast/ToastComponent';
import LoginComponent from './components/Auth/LoginComponent';
import ForgotPasswordComponent from './components/Auth/ForgotPasswordComponent';
import RequireNewPasswordComponent from './components/Auth/RequireNewPasswordComponent';
import VerifyContactComponent from './components/Auth/VerifyContactComponent';
import LogoutNotification from './components/Auth/LogoutNotification';
import ConfirmSignUp from './components/Auth/ConfirmSignUp';
import Loader from './components/Loader/Loader';
import './App.scss';
import fonts from './fonts';
import SignUpComponent from './components/Auth/Signup';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

export class App extends Component {
  constructor(props) {
    super(props);

    this.handleUpdatedTokenClaims = this.handleUpdatedTokenClaims.bind(this);

    this.state = {
      idToken: null,
      datasetsUpdated: false,
      eulaChecked: false,
      showEula: false,
      showDashboard: false,
      showToast: false,
      sessionTimedOut: false,
      sessionTimer: null,
      toastType: null,
      toastMessage: null
    };

    this.userGroups = [];

    this.inactivityTimeout = 1800000;
  }

  async componentDidMount() {
    this.initActivityTimer();

    this.userGroups = await this.getUserGroups();
    let userApps = [];
    if (this.userGroups && this.userGroups.length > 0) {
      userApps = this.userGroups.map((group) => {
        const groupParts = group.split('_');
        return groupParts[2];
      });
    }

    const needsEula = await this.checkForEula(this.userGroups);
    const { showDashboard } = this.state;
    if (userApps.includes('client') && !needsEula) {
      const { datasetsUpdated } = this.state;
      if (datasetsUpdated && !showDashboard) {
        this.setState({
          showDashboard: true
        });
      }
    }

    if (!needsEula && !showDashboard) {
      this.setState({
        showDashboard: true
      });
    }
  }

  cancelLogout = () => {
    this.setState({
      sessionTimedOut: false
    });
    this.initActivityTimer();
  };

  initActivityTimer = () => {
    let activeTimestamp = Date.now();

    // check for browser "awake" at 2 second intervals
    // If browser just "woke up", check if the time is longer than the allowed timeout
    // hmmm?
    setInterval(() => {
      const currentTime = Date.now();
      if (currentTime > activeTimestamp + 2000 * 2) {
        // ignore small delays
        // Probably just woke up
        const asleepTime = currentTime - activeTimestamp;
        if (asleepTime > this.inactivityTimeout) {
          this.handleLogout();
        }
      }
      activeTimestamp = currentTime;
    }, 2000);

    // Once we have an "awake" browser, keep track of user interaction
    // and logout if inactive period is longer than allowed
    let sessionTimer = setTimeout(this.handleSessionTimeout, this.inactivityTimeout);
    const resetTimer = () => {
      clearTimeout(sessionTimer);
      sessionTimer = setTimeout(this.handleSessionTimeout, this.inactivityTimeout);
    };
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach((name) => {
      document.addEventListener(name, resetTimer, true);
    });

    this.setState({
      sessionTimer
    });
  };

  handleLogout = () => {
    const session = window.sessionStorage;
    session.removeItem(storage.USERS); // clear storage value
    Auth.signOut();
  };

  handleSessionTimeout = () => {
    const { sessionTimer } = this.state;
    clearTimeout(sessionTimer);
    this.setState({
      sessionTimedOut: true
    });
  };

  getUserGroups = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const userGroups = idToken.payload['cognito:groups'];
      return userGroups;
    } catch (e) {
      return [];
    }
  };

  getUserIdentityID = async () => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const idAttr = idToken.payload[c.IDPOOL_ID_ATTR];
    return idAttr;
  };

  checkForEula = async (userGroups = null) => {
    let groups = userGroups;
    if (!userGroups) {
      groups = await this.getUserGroups();
    }
    const { showEula } = this.state;
    let needsEula;

    if (groups && groups.length > 0) {
      needsEula = groups.some((grp) => {
        return grp.match(/.*_noEula_upgroup/g);
      });
    } else {
      needsEula = false;
    }
    if (needsEula !== showEula) {
      this.setState({
        showEula: needsEula
      });
    }

    this.setState({
      eulaChecked: true
    });

    return needsEula;
  };

  handleUpdatedTokenClaims = async () => {
    const userGroups = await this.getUserGroups();
    const userApps = userGroups.map((group) => {
      const groupParts = group.split('_');
      return groupParts[2];
    });

    if (userApps.includes('client')) {
      const { datasetsUpdated } = this.state;
      if (datasetsUpdated) {
        this.checkForEula(userGroups);
        this.setState({
          showDashboard: true
        });
      }
    } else {
      this.checkForEula(userGroups);
      this.setState({
        showDashboard: true
      });
    }
  };

  refreshTokens = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    return new Promise((resolve, reject) => {
      currentUser.refreshSession(session.refreshToken, async (err, newTokens) => {
        if (err) {
          return reject(err);
        }
        this.setState({
          idToken: newTokens.idToken
        });

        // After updating the idToken, we need to rebuild the IAM credentials with it
        const credentials = await Auth.currentCredentials();
        credentials.params.Logins[
          `cognito-idp.${process.env.REACT_APP_env_cognito_region}.amazonaws.com/${process.env.REACT_APP_env_userpool_id}`
        ] = newTokens.idToken.jwtToken;

        credentials.refresh((error) => {
          if (error) {
            return reject(error);
          }
          return resolve(newTokens);
        });
      });
    });
  };

  setToast = (toast) => {
    this.setState({
      toastType: toast.type,
      toastMessage: toast.message
    });
  };

  setUserIdentityAttr = async () => {
    try {
      let id;
      const currentUser = await Auth.currentAuthenticatedUser();
      const idAttr = await this.getUserIdentityID();

      if (!idAttr) {
        const identityPoolData = await Auth.currentUserInfo();
        const identityID = identityPoolData.id;
        await Auth.updateUserAttributes(currentUser, {
          [c.IDPOOL_ID_ATTR]: identityID
        });
        id = identityID;
        await this.refreshTokens();
      } else {
        id = idAttr;
      }
      return id;
    } catch (e) {
      console.log(e);
    }
  };

  showView = (whichView) => {
    const { eulaChecked, idToken, showDashboard } = this.state;
    const { authData } = this.props;

    if (whichView === 'eula' && eulaChecked) {
      return (
        <Eula
          refreshTokens={this.refreshTokens}
          idToken={idToken}
          handleUpdatedTokenClaims={this.handleUpdatedTokenClaims}
          showToast={(toast) => {
            this.setToast(toast);
            this.toggleToast();
          }}
        />
      );
    }

    if (whichView === 'dashboard' && eulaChecked && showDashboard) {
      return (
        <Dashboard
          authData={authData}
          signOut={this.handleLogout}
          showToast={(toast) => {
            this.setToast(toast);
            this.toggleToast();
          }}
        />
      );
    }

    return <Loader open />;
  };

  toggleToast = () => {
    const { showToast } = this.state;
    this.setState({
      showToast: !showToast
    });
  };

  render() {
    const { showEula, showToast, toastType, toastMessage, sessionTimedOut } = this.state;

    return (
      <>
        {showEula ? this.showView('eula') : this.showView('dashboard')}
        <ToastComponent
          showToast={showToast}
          toastType={toastType}
          toastMessage={toastMessage}
          toggle={this.toggleToast}
        />
        <LogoutNotification
          open={sessionTimedOut}
          handleCancel={this.cancelLogout}
          handleLogout={this.handleLogout}
          inactivityTimeout={this.inactivityTimeout}
        />
      </>
    );
  }
}

export default 
  withAuthenticator(
    App,
    false,
    [
      <SignUpComponent key={1} />,
      <LoginComponent key={1} />,
      <ForgotPasswordComponent key={1} />,
      <RequireNewPasswordComponent key={1} />,
      <VerifyContactComponent key={1} />,
      <ConfirmSignUp key={1} />
    ],
    null,
    {
      toast: {
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        left: '50%',
        right: 'auto',
        top: 'auto',
        bottom: '24px',
        width: 'auto',
        transform: 'translateX(-50%)',
        color: 'rgb(97, 26, 21)',
        backgroundColor: 'rgb(253, 236, 234)',
        padding: '14px 16px',
        fontSize: '0.875rem',
        fontFamily: `"${fonts.family.ROBOTO}", "Helvetica", "Arial", sans-serif`,
        fontWeight: '400',
        lineHeight: '1.43',
        borderRadius: '4px',
        letterSpacing: '0.01071em'
      }
    }
);
